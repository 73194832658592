export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://arhamnatiq.com',
	},
	{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/arham-natiq25',
	},
	{
		id: 3,
		name: 'Facebook',
		icon: 'facebook',
		url: 'https://www.facebook.com/arham.natiq',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/arham-natiq25',
	},
	{
		id: 5,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://www.instagram.com/sh_arham25',
	},
];
